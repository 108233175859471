<template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-alert v-if="notification !== ''"  dense text :type="`${actionClass}`" class="mt-5" >
                  {{ notification }}
                 </v-alert>
                <v-form  ref="form" v-model="valid" lazy-validation >
                    <v-row no-gutters class="ma-0">
                        <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="mr-13">
                            <label for=""><strong>Category</strong></label>
                            <v-text-field
                            v-model="category"
                            :rules="categoryRules"
                            required
                            outlined
                            dense
                            ></v-text-field>
                        </v-col>
                         <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="mr-13">
                            <label for=""><strong>Description</strong></label>
                            <v-textarea
                            v-model="description"
                            :rules="descriptionRules"
                            required
                            outlined
                            dense
                            ></v-textarea>
                        </v-col>
                         <v-card-actions dark>
                            <v-spacer></v-spacer>
                             <v-btn  color="blue darken-1" @click="cancel" dark>
                                Cancel
                            </v-btn>

                            <v-btn  :disabled="!valid || loading" :color="`${zamuYellow3}`"  class="mr-4 zamu-sub-heading "  @click="save" dark >
                            Submit
                            <v-progress-circular
                                indeterminate
                                color="primary"
                                class="ml-4"
                                :size="20"
                                v-if="loading"
                                ></v-progress-circular>
                            </v-btn>
                         </v-card-actions>

                    </v-row>

                </v-form>
              </v-container>
            </v-card-text>
          </v-card>
</template>

<script>
import coreMixin from '@/mixins/coreMixin';
import colorMixin from '@/mixins/colorMixin';


export default {
    name: 'EditTeam',
    mixins: [coreMixin, colorMixin],
     data () {
       return {
            post: null,
            category: '',
            description: '',
            notification: '',
            actionClass: '',
            formTitle: 'Edit Blog Category',
             valid: false,
             loading: false,
             categoryID: null,
       };
     },


    async mounted() {
       const id = this.$route.params.id;
       this.categoryID = id;
        this.post = await this.retrieveCategory(id);
        this.category = this.post.category;
        this.description = this.post.description;

    },
    methods: {
        async retrieveCategory(id) {
              const endpoint = `blog-categories/category/${id}`;

              const fullPayload = {
                  app: this.apiUrl,
                  endpoint,
              };

              try {
                  const response = await this.axiosGetRequest(fullPayload);
                  const post = response.status === 200 ? response.data : null;
                  return post;
              } catch (error) {
                return null;
              }
          },
          async save(){
              const formvalid = this.$refs.form.validate();
              if(!formvalid) {
                  return;
              }

                this.loading = true;

                    const payload = {
                          category: this.category,
                          description: this.description,
                      };
                      const fullPayload = {
                          app: this.apiUrl,
                          params: payload,
                          endpoint: `blog-categories/update/${this.categoryID}`,
                      };
                        try {
                          const res = await this.axiosPutRequest(fullPayload);
                          this.loading = false;
                          this.notification = res.data ? 'Blog Category updated successful' : 'Team member failed to update';
                          this.actionClass = res.data  ? 'success' : 'error';
                       

                      } catch (error) {
                          this.notification = 'Blog Category failed to update. Contact the admin';
                          this.actionClass = 'error';
                          this.loading = false;
                          return error.response;
                      }

          },
          cancel () {
           this.$router.push('/admin/blog-categories');

          },
        }

}
</script>

<style>

</style>